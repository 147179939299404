/* Common styles for both desktop and mobile */
.subheader-master {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  background-color: white;
  border-radius: 10px;
  padding: 0 1rem; /* Add padding to the left and right */
}

.navlink-master {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0; /* Remove default padding */
  margin: 0; /* Remove default margin */
}

.navlink-master li {
  list-style-type: none;
  padding: 0 10px; /* Adjust padding for spacing */
  cursor: pointer;
}

.backnavigation-master {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.musterforms {
  width: 100%;
  background-color: white;
  margin-top: 10px;
  border-radius: 10px;
}

.mustercontent {
  width: 100%;
  height: 30px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: blueviolet;
  font-size: 18px;
  padding-left: 10px;
  padding-top: 3px;
  color: white;
}

/* Media query for mobile devices */
@media screen and (max-width: 768px) {
  .subheader-master {
      flex-direction: column;
      height: auto; /* Adjust height as needed for mobile */
      border-radius: 10px; /* Remove border-radius for mobile */
      padding: 0.5rem; /* Adjust padding for mobile */
  }

  .navlink-master {
      flex-direction: column; /* Stack items vertically */
      align-items: flex-start; /* Align items to the start */
      width: 100%; /* Make it full width */
  }

  .navlink-master li {
      width: 100%; /* Make it full width */
      padding: 5px 10px; /* Adjust padding for mobile */
      background-color: rgb(218, 218, 218);
      margin-top: 0.5rem;
      border-radius: 5px;
  }

  .backnavigation-master {
      width: 100%; /* Make it full width */
      padding: 0.5rem 0; /* Add padding for spacing */
  }
}

body {
  overflow: hidden;
}

.dashboard {
  display: flex;
  height: 100vh;
}

.sidebar1 {
  position: relative;
}

.main-content,
.content {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.main-content {
  height: 100vh;
  width: 100%;
  overflow: auto; /* Add this line to make content scrollable */
  background-color: white;
}

.content-master {
  height: 100vh; /* Set a fixed height for the header */
  overflow: auto; /* Add this line to make content scrollable */
  padding: 1rem;
  margin: 0.5rem;
  margin-top: 4.2rem;
  border-radius: 1rem;
  background-color: rgb(229, 230, 231);
  font-family: "Poppins", sans-serif;
}

.navbar1 {
  position: fixed;
  width: 100%;
}

.main-content::-webkit-scrollbar,
.content::-webkit-scrollbar {
  display: none;
}

.backnavigation-master span {
  text-decoration: none;
  color: black;
  font-weight: 500;
}

@media screen and (max-width: 1115px) and (max-height: 607px) {
  /* Specific styles for this viewport size */
}
