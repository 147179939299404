.widget-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #f5f5f5;
  }
  
  .widget-content {
    background-color: #ffffff;
    padding: 32px;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
  }
  
  .widget-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 24px;
    margin-left: 1.7rem;
    color: #333333;
  }
  
  .form-group .custom-input {
    border: none;
    border-bottom: 1px solid #cccccc;
    border-radius: 0;
    padding: 8px 0;
  }
  
  .form-group .custom-input:focus {
    border-bottom: 2px solid #6b46c1;
    box-shadow: none;
  }
  
  .forgot-password-link {
    font-size: 14px;
    color: #6b46c1;
    margin-top: 3rem;
    margin-left: 2rem;
  }
  
  .sign-in-button {
    width: 100%;
    background-color: #6b46c1;
    color: #ffffff;
    padding: 12px;
    margin-bottom: 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .sign-in-button:hover {
    background-color: #5938b6;
  }
  .floating-label{
    background-color: transparent;
  }
  .backnavigation{
    margin-left: 35rem;
    text-decoration: none;
  }
  .backnavigation span{
    text-decoration: none;
    color: black;
    font-weight: 500;
  }