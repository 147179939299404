body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  
  height: 100vh;
  background-color: #f0f2f5;
}
 

 
