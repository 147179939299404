.header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 2.5px;
  background-color: #dbdbdb;
  color: black;
  height: 60px;
  width: 100%;
  font-family: "Poppins", sans-serif;
  z-index: 1 !important;
}

.head-logo {
  margin-left: 2rem;
  font-family: "Poppins", sans-serif;
}

.circles-container {
  display: flex;
  margin-right: 7rem;
}

.circle {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  margin-left: 6px;
  cursor: pointer;
}

.c1 {
  background-color: blueviolet;
}

 

.main-container {
  padding-right: 4rem !important;
  display: flex;
  align-items: center;
}

.headlogolink {
  text-decoration: none !important;
  color: black;
}

.c1link {
  text-decoration: none;
}

.toggle-button {
  display: none;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  margin-right: 10px;
 
}

@media (max-width: 768px) {
  .circles-container {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 70px;
    right: 5px;
    background-color: #bdbdbd;
    z-index: 2;
    
    border-radius: 5px;
  }

   
  .circle{
   margin: 5px;
  }

  .toggle-button {
    display: block;
  }
}
.sidebar2 {
  position: fixed;
  top: 60px;
  right: 0;
  height: 100vh;
  width: 200px; /* Adjust width as needed */
  background-color: #ffffff;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.4s ease-in-out;
  transform: translateX(100%);
  overflow-y: auto;
  z-index: 3;
  border-radius: 5px;
}
.sidebar2::-webkit-scrollbar {
  width: 0 !important;
}
.sidebar2.show {
  transform: translateX(0);
}

.sidebar2 ul {
  display:block !important;
  align-items: center;
  justify-content: center  !important;
  list-style: none;
  padding: 0;
  padding-top: 20px;
  padding-left: 18px;
  padding-right: 18px;
  
   
}

.sidebar2 ul li {
  padding-top: 8px;
  cursor: pointer;
  margin-bottom: 8px;
  padding-left: 17px;
  color: black;
  padding-bottom: 5px;
  background-color: #e6e5e5;
  border-radius: 5px;
  
}
.licon{
  margin-right: 5px;
  color: blueviolet;
  
}
.sidebar2 ul li:hover .licon,
.sidebar2 ul li:hover {
  color: whitesmoke; /* Change icon and text color to white on hover */
}
.sidebar2 ul li:hover  {
 background-color: blueviolet;
  
 border-radius: 5px;

  
}

.sidebar2 ul li:last-child {
  border-bottom: none; /* Remove border from the last list item */
}

.sidebar2 ul li a {
  text-decoration: none;
  color: #333;
}

/* Rest of your CSS remains unchanged */
.option1{
  background-color: antiquewhite;
  height: 10px;
  width: 10px;
  border-radius: 5px;
  margin-right: 5px;
}
 
