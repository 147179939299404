/* Common styles for both desktop and mobile */
.subheader {
    width: 100%;
    height: 40px;
    background-color: white;
    border-radius: 10px;
  }
  
  .navlink {
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: 0; /* Remove default padding */
    margin-top: 0; /* Remove default margin */
  }
  
  .navlink li {
    list-style-type: none;
    padding-top: 9px;
    width: 100px;
    height: 100%;
    padding-left: 20px;
    cursor: pointer;
  }
  .musterforms{
    width: 100%;
    background-color: white;
    margin-top: 10px;
    border-radius: 10px;
  }
  .mustercontent{
    width: 100%;
    height: 33px !important;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: blueviolet;
    font-size: 18px;
    padding-left: 10px;
    padding-top: 3px;
    color: white;
  }
  .backnavigation{
    cursor: pointer;
  }
  /* Media query for mobile devices */
  @media screen and (max-width: 768px) {
    .subheader {
      height: auto; /* Adjust height as needed for mobile */
      border-radius: 10px; /* Remove border-radius for mobile */
    }
    
    .navlink {
      flex-direction: column; /* Stack items vertically */
      align-items: flex-start; /* Align items to the start */
    }
  
    .navlink li {
      width: auto; /* Auto width for mobile */
      padding: 5px 10px; /* Adjust padding for mobile */
    }
    .backnavigation{
      margin-left: 10px !important;
      padding-bottom: 10px;
    }
  
  }
  body {
    overflow: hidden;
  }
  
  .dashboard {
    display: flex;
    height: 100vh;
  }
  
  .sidebar1 {
    position: relative;
  }
  .main-content,
  .content {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  .main-content {
    height: 100vh;
    width: 100%;
    overflow: auto; /* Add this line to make content scrollable */
     
    
    background-color: white;
  }
  
  .content {
    height: 100vh; /* Set a fixed height for the header */
    overflow: auto; /* Add this line to make content scrollable */
    padding: 1rem;
    margin: 0.5rem;
    margin-top: 4.2rem;
    border-radius: 1rem;
    background-color: rgb(229, 230, 231);
    font-family: "Poppins", sans-serif;
  }
  
   
  .navbar1{
    position: fixed;
    width: 100%;
  }
   
  .main-content::-webkit-scrollbar,
  .content::-webkit-scrollbar {
    display: none;
  }
  .table-auto {
    width: 100%;
  }
  
  .table-header {
    padding: 0.5rem 1rem;
  }
  
  .table-cell {
    border: 1px solid #e5e7eb; /* Tailwind's border-gray-200 */
    padding: 0.5rem 1rem;
  }
  
  .button-edit {
    background-color: #3b82f6; /* Tailwind's bg-blue-500 */
    color: white;
    padding: 0.5rem;
    border-radius: 0.5rem; /* Tailwind's rounded-lg */
    border: none;
  }
  
  .button-delete {
    background-color: #ef4444; /* Tailwind's bg-red-500 */
    color: white;
    padding: 0.5rem;
    border-radius: 0.5rem; /* Tailwind's rounded-lg */
    border: none;
  }
  