.formdiv{
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 1rem;
}
.group {
    position: relative;
    z-index: 1 !important;
   }
   
   .input {
    font-size: 16px;
    padding: 10px 10px 10px 5px;
    display: block;
    width: 100%;
    border: none;
    border-bottom: 1px solid #515151;
    background: transparent;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
   }
   
   .input:focus {
    outline: none;
   }
   
   label {
    color: #999;
    font-size: 18px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 10px;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
   }
   
   .input:focus ~ label, .input:valid ~ label {
    top: -10px;
    font-size: 14px;
    color: #000000;
   }
   
   .bar {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
   }
   
   .bar:before, .bar:after {
    content: '';
    height: 2px;
    width: 0;
    bottom: 1px;
    position: absolute;
    background: black;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
   }
   
   .bar:before {
    left: 50%;
    margin-bottom: 0.5rem !important;
   }
   
    
   .highlight {
    position: absolute;
    height: 60%;
    width: 100px;
    top: 25%;
    left: 0;
    pointer-events: none;
    opacity: 0.5;
    background-color: aquamarine !important;
   }
   
   .input:focus ~ .highlight {
    animation: inputHighlighter 0.3s ease;
   }
   .btn-primary{
    background-color: blueviolet !important;
    border: none !important;
    height: 40px  !important;
    width: 120px;
    margin-top: 1rem;
   }
   .btns{
    margin-left: 3.5rem;
    display: flex  !important;
    align-items: center !important;
     
   }
   @keyframes inputHighlighter {
    from {
     background: #000000;
    }
   
    to {
     width: 0;
     background: transparent;
    }
   }
   .search-input{
    margin-top: 0.5rem;
    margin-left: 1rem;
    width: 300px;
   }
   .search-label{
    margin-left: 1rem;
    
   }
   .closeButton{
    height: 40px  !important;
    width: 120px;
    margin-top: 1rem;
    background-color: white !important;
    border: none;
    background-color: rgb(243, 241, 241) !important;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
    margin-left: 1rem;
    border-radius: 5px;
   }
   .suggestions{
    list-style-type: none;
    background-color: rgb(240, 238, 238);
    width: 100%;
    padding: 5px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    cursor: pointer;
   }
   @media screen and (max-width: 768px) {
    .btns{
        margin-left:0;
    }
    .closeButton{
        height: 40px  !important;
        width: 140px;
        margin-top: 1rem;
        font-size:12px !important ;
    }
    .btn-primary{
    font-size:12px !important ;
    }
   }