/* Common styles for both desktop and mobile */
.subheader-party {
    display: flex !important;
      width: 100%;
      height: 40px;
      background-color: white;
      border-radius: 10px;
    }
  
    .navlink-party {
        display: flex;
        align-items: center;
        height: 100%;
        padding-left: 0; /* Remove default padding */
        margin-top: 0; /* Remove default margin */
      }
      
      .navlink-party li {
        list-style-type: none;
        padding-top: 9px;
        width: fit-content;
        height: 100%;
        padding-left: 20px;
        cursor: pointer;
      }
  .musterforms{
    width: 100%;
    background-color: white;
    margin-top: 10px;
    border-radius: 10px;
  }
  .mustercontent{
    display: flex;
    align-items: center;
    width: 100%;
    height: 33px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: blueviolet;
    font-size: 18px;
    padding-left: 10px;
    padding-top: 3px;
    color: white;
  }
  .backnavigation{
    cursor: pointer;
  }
  .formheader-party{
    margin-left: 12rem;
    border: none;
    color: rgb(128, 124, 124) !important;
    font-size: 15px !important;
  }
  .formheader button{
    color: white !important;
    background-color: white;
  }
  /* Media query for mobile devices */
  @media screen and (max-width: 768px) {
    .subheader-party {
        height: auto; /* Adjust height as needed for mobile */
        border-radius: 10px; /* Remove border-radius for mobile */
      }
      
      .navlink-party {
        flex-direction: column; /* Stack items vertically */
        align-items: flex-start; /* Align items to the start */
      }
    
      .navlink-party li {
        width: 200px; /* Auto width for mobile */
        padding: 5px 10px; /* Adjust padding for mobile */
        background-color: rgb(218, 218, 218);
        margin-left: 1.8rem;
        margin-top: 0.5rem;
        border-radius: 5px;
        cursor: pointer;
      }
      .backnavigation-party{
        margin-left: 1.8rem !important;
        margin-top: 0.5rem;
       
      }
    .addbtn{
        margin-left: 6rem !important;
    }
     
  }
  body {
    overflow: hidden;
  }
  
  .dashboard {
    display: flex;
    height: 100vh;
  }
  
  .sidebar1 {
    position: relative;
  }
  .main-content,
  .content {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  .main-content {
    height: 100vh;
    width: 100%;
    overflow: auto; /* Add this line to make content scrollable */
     
    
    background-color: white;
  }
  
  .content {
    height: 100vh; /* Set a fixed height for the header */
    overflow: auto; /* Add this line to make content scrollable */
    padding: 1rem;
    margin: 0.5rem;
    margin-top: 4.2rem;
    border-radius: 1rem;
    background-color: rgb(229, 230, 231);
    font-family: "Poppins", sans-serif;
  }
  
   
  .navbar1{
    position: fixed;
    width: 100%;
  }
   
  .main-content::-webkit-scrollbar,
  .content::-webkit-scrollbar {
    display: none;
  }
  .table-auto {
    width: 100%;
  }
  
  .table-header {
    padding: 0.5rem 1rem;
  }
  
  .table-cell {
    border: 1px solid #e5e7eb; /* Tailwind's border-gray-200 */
    padding: 0.5rem 1rem;
  }
  
  .button-edit {
    background-color: #3b82f6; /* Tailwind's bg-blue-500 */
    color: white;
    padding: 0.5rem;
    border-radius: 0.5rem; /* Tailwind's rounded-lg */
    border: none;
  }
  
  .button-delete {
    background-color: #ef4444; /* Tailwind's bg-red-500 */
    color: white;
    padding: 0.5rem;
    border-radius: 0.5rem; /* Tailwind's rounded-lg */
    border: none;
  }
  
  .addbtn{
     margin-left: 85%;
     border: none;
     height: auto;
     text-shadow: none;
     background-color: white;
     color: black;
     margin-bottom: 3.4px;
     border-radius: 5px;
     
    
  }
  .addbtn :hover{
    background-color: white !important;
  }
  .party-btn-edit{
    border: none;
    font-size: 20px;
    color: rgb(5, 192, 5);
  }
  .party-btn-delete{
    border: none;
    font-size: 20px;
    color: red;
  }
  .backnavigation-party{
    margin-left: 54rem;
    text-decoration: none;
    cursor: pointer;
    
  }
  .backnavigation-party span{
    text-decoration: none;
    color: black;
    font-weight: 500;
    cursor: pointer;
  }