.app {
  display: flex;
  height: 100vh;
  position: relative;
}

.sidebar {
  width: 70px;
  background-color: #e7e7e7;
  padding: 10px;
  transition: width 0.3s ease, background-color 0.3s ease;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto; /* Add this line to make sidebar scrollable */
  height: 100%; /* Ensure sidebar takes full height of its container */
  position: relative; /* Ensure absolute positioning of footer is relative to the sidebar */
}

.sidebar::-webkit-scrollbar {
  display: none;
  width: 1px; /* Hide the scrollbar for Webkit browsers (Chrome, Safari, etc.) */
}

.expanded .sidebar {
  width: 200px;
}

.expand-button {
  position: absolute;
  top: 50%;
  left: calc(100% - 10px);
  transform: translate(-50%, -50%);
  z-index: 999;
  background-color: #6b46c1;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.expand-button:hover {
  background-color: #2980b9;
}

.sidebar-logo {
  text-align: center;
  margin-bottom: 20px;
}

.sidebar-logo img {
  width: 100%;
  height: 50px;
  max-width: 50px;
  border-radius: 50%;
}

.sidebar-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 5px;
  transition: background-color 0.3s ease, height 0.3s ease; /* Added height transition */
  background-color: #fff;
  border-radius: 1rem;
  height: 30px;
  margin-left: 0.5rem;
  margin-right: 1rem;
}

.sidebar-item:hover {
  background-color: #b8b8b8;
}

.expanded-item {
  height: 60px; /* Expanded height */
}

.description {
  font-family: "Poppins", sans-serif;
}

.sidebar-icon {
  margin-right: 10px;
  color: #6b46c1;
  font-size: 15px;
  margin-bottom: 3px;
}

.iconsdiv {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  width: 40px;
}

.sidebar-details {
  color: black;
}

.details {
  font-size: 1rem;
  font-weight: 600;
}

.sidebar-sub-options {
  padding-left: 20px;
  margin-top: 5px;
   
}

.sidebar-sub-option {
  padding: 5px;
  font-size: 14px;
  width: 130px;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.sidebar-sub-option:hover {
  background-color: #d1d1d1;
}
.sub-sidebar-icon{
  color:#6b46c1;
}
.sidebar-footer {
  position: absolute;
  margin-top: 10px;
  left: 23px;
  width: calc(100% - 50px); /* Adjust width to fit inside the sidebar with padding */
  border-radius: 1rem;
  align-items: center;
  background-color: #fafafa;
}

.sidebar-footer:hover {
  background-color: #b8b8b8;
  color: #fff;
}

.user-details {
  display: flex;
  flex-direction: row;
  width: auto;
}

.user-details:hover {
  color: #fff;
}

.user-info {
  align-items: center;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  height: auto;
  width: auto;
  position: sticky;
}

.user-img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-top: 1rem;
  margin-left: 3px;
  border: 3px solid #6b46c1;
}

.user-name {
  font-weight: bold;
  font-size: 15px;
  color: black;
  margin-left: 5px;
}

.logout-button button {
  background-color: transparent;
  color: black;
  border: none;
  cursor: pointer;
  font-size: 15px;

}

/* .logout-button button:hover {
  background-color: #6b46c1;
  color: #fff;
} */
/* @media screen and (max-width: 768px) {
  .expanded .sidebar {
    width: 150px;
  }
} */