/* Common styles for both desktop and mobile */
.subheader-country {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  background-color: white;
  border-radius: 10px;
  padding: 0 1rem; /* Add padding to the left and right */
}

.navlink-country {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0; /* Remove default padding */
  margin: 0; /* Remove default margin */
}

.navlink-country li {
  list-style-type: none;
  padding: 0 10px; /* Adjust padding for spacing */
  cursor: pointer;
}

.backnavigation-country {
  display: flex;
  align-items: center;
  cursor: pointer;
}

  .musterforms{
    width: 100%;
    background-color: white;
    margin-top: 10px;
    border-radius: 10px;
  }
  .mustercontent{
    display: flex;
    align-items: center;
    width: 100%;
    height: 33px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: blueviolet;
    font-size: 18px;
    padding-left: 10px;
    padding-top: 3px;
    color: white;
  }
  .backnavigation-country{
    cursor: pointer;
  }
  .formheader-country{
    margin-left: 10rem !important;
    border: none !important;
    color: rgb(128, 124, 124) !important;
    font-size: 15px !important;
  }
  .formheader button{
    color: white !important;
    background-color: white;
  }
  /* Media query for mobile devices */
  @media screen and (max-width: 768px) {
    .subheader-country {
        flex-direction: column;
        height: auto; /* Adjust height as needed for mobile */
        border-radius: 10px; /* Remove border-radius for mobile */
        padding: 0.5rem; /* Adjust padding for mobile */
    }
  
    .navlink-country{
        flex-direction: column; /* Stack items vertically */
        align-items: flex-start; /* Align items to the start */
        width: 100%; /* Make it full width */
    }
  
    .navlink-country li {
        width: 100%; /* Make it full width */
        padding: 5px 10px; /* Adjust padding for mobile */
        background-color: rgb(218, 218, 218);
        margin-top: 0.5rem;
        border-radius: 5px;
    }
  
    .backnavigation-country {
        width: 100%; /* Make it full width */
        padding: 0.5rem 0; /* Add padding for spacing */
    }
    .table-header{
      width: 25px !important;
      font-size: 10px !important;
      margin: 0 !important;
      margin-right: 10px;
      padding: 0 !important;
      padding-left: 0;
      padding-right: 0;
    }
    .table-cell{
      font-size: 9px;
      margin: 0 !important;
      padding: 0 !important;
      padding-left: 0;
      padding-right: 0;
    }

    .country-btn-edit{
      font-size: 10px !important;
    }
    .country-btn-delete{
      font-size: 10px !important;
    }
    .table1{
      width: 255px !important; 
    }

  }
  body {
    overflow: hidden;
  }
  
  .dashboard {
    display: flex;
    height: 100vh;
  }
  
  .sidebar1 {
    position: relative;
  }
  .main-content,
  .content {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  .main-content {
    height: 100vh;
    width: 100%;
    overflow: auto; /* Add this line to make content scrollable */
     
    
    background-color: white;
  }
  
  .content {
    height: 100vh; /* Set a fixed height for the header */
    overflow: auto; /* Add this line to make content scrollable */
    padding: 1rem;
    margin: 0.5rem;
    margin-top: 4.2rem;
    border-radius: 1rem;
    background-color: rgb(229, 230, 231);
    font-family: "Poppins", sans-serif;
  }
  
   
  .navbar1{
    position: fixed;
    width: 100%;
  }
   
  .main-content::-webkit-scrollbar,
  .content::-webkit-scrollbar {
    display: none;
  }
  .backnavigation-country span {
    text-decoration: none;
    color: black;
    font-weight: 500;
  }
  .table-auto {
    width: 100%;
  }
  
  .table-header {
    padding: 0.5rem 1rem;
  }
  
  .table-cell {
    border: 1px solid #e5e7eb; /* Tailwind's border-gray-200 */
    padding: 0.5rem 1rem;
  }
  
  .button-edit {
    background-color: #3b82f6; /* Tailwind's bg-blue-500 */
    color: white;
    padding: 0.5rem;
    border-radius: 0.5rem; /* Tailwind's rounded-lg */
    border: none;
  }
  
  .button-delete {
    background-color: #ef4444; /* Tailwind's bg-red-500 */
    color: white;
    padding: 0.5rem;
    border-radius: 0.5rem; /* Tailwind's rounded-lg */
    border: none;
  }
  
  .addbtn{
     margin-left: 85%;
     border: none;
     height: auto;
     text-shadow: none;
     background-color: white;
     color: black;
     margin-bottom: 3.4px;
     border-radius: 5px;
     
    
  }
  .addbtn :hover{
    background-color: white !important;
  }
  .country-btn-edit{
    border: none;
    font-size: 20px;
    color: rgb(5, 192, 5);
  }
  .country-btn-delete{
    border: none;
    font-size: 20px;
    color: red;
  }

  .group-1{
    position: relative;
    z-index: 1 !important;
   }
   .input-1{
    font-size: 16px;
    padding: 10px 10px 10px 5px;
    display: block;
    width: 100%;
    border: none;
    border-bottom: 1px solid #515151;
    background: transparent;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
   }
   
   .input-1:focus {
    outline: none;
   }
   .input-1:focus ~ label, .input-1:valid ~ label {
    top: -10px;
    font-size: 14px;
    color: #000000;
   }
   .input-1:focus ~ .highlight {
    animation: inputHighlighter 0.3s ease;
   }